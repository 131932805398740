<template>
  <v-card flat>
    <v-card-title class="font-weight-bold px-5">Hồ sơ của tôi</v-card-title>
    <!-- Start: Alert Error -->
    <v-alert
      v-if="accountStatusRequest.value === 'error-updateInfo'"
      class="mb-8 mx-5"
      dense
      text
      type="error"
    >
      {{ accountStatusRequest.message }}
    </v-alert>
    <!-- End: Alert Error -->
    <!-- Start: Card Body -->
    <div class="d-flex">
      <!-- Start: Avatar -->
      <div class="pl-5 pr-8">
        <div class="font-weight-bold mb-2">Ảnh đại diện</div>
        <tp-input-avatar
          :src="user.avatar.slice(0, 5) !== 'blob:' ? user.avatar : undefined"
          height="136px"
          width="136px"
          @change="user.avatar = $event"
          @updateFormData="formData = $event"
        ></tp-input-avatar>
      </div>
      <!-- End: Avatar -->
      <!-- Start: User info -->
      <v-form ref="form" v-model="userValid" class="flex-grow-1">
        <v-container class="py-0" fluid>
          <v-row>
            <!-- Start: Name -->
            <v-col class="pl-0 pr-5 py-0" cols="12" md="6">
              <div class="font-weight-bold mb-2">Họ tên</div>
              <v-text-field
                v-model="user.name"
                :rules="nameRules"
                class="text-body-1"
                dense
                flat
                outlined
                required
                single-line
                solo
              ></v-text-field>
            </v-col>
            <!-- End: Name -->
            <!-- Start: Email -->
            <v-col class="pl-0 pr-5 py-0" cols="12" md="6">
              <div class="font-weight-bold mb-2">Email</div>
              <v-text-field
                :value="user.email"
                background-color="grey lighten-4"
                class="text-body-1"
                dense
                disabled
                outlined
                single-line
              ></v-text-field>
            </v-col>
            <!-- End: Email -->
            <!-- Start: Phone -->
            <v-col class="pl-0 pr-5 py-0" cols="12" md="6">
              <div class="font-weight-bold mb-2">Số điện thoại</div>
              <v-text-field
                :value="user.phone"
                background-color="grey lighten-4"
                class="text-body-1"
                dense
                disabled
                outlined
                single-line
              ></v-text-field>
            </v-col>
            <!-- End: Phone -->
            <!-- Start: Role -->
            <v-col class="pl-0 pr-5 py-0" cols="12" md="6">
              <div class="font-weight-bold mb-2">Vị trí</div>
              <v-text-field
                background-color="grey lighten-4"
                class="text-body-1"
                dense
                disabled
                outlined
                readonly
                single-line
                value="Nhân viên bán hàng"
              ></v-text-field>
            </v-col>
            <!-- End: Role -->
          </v-row>
        </v-container>
      </v-form>
      <!-- End: User info -->
    </div>
    <!-- End: Card Body -->
    <v-card-actions class="px-5 pb-5">
      <v-btn
        :disabled="!userValid"
        class="btn-text--normal"
        color="primary"
        depressed
        @click="userValid ? updateInfo() : null"
      >
        Lưu thay đổi
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      formData: null,
      nameRules: [value => !!value || "Bạn chưa nhập tên."],
      userValid: true
    };
  },
  computed: {
    accountStatusRequest() {
      return this.$store.getters["ACCOUNT/statusRequest"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    uploadedAvatar() {
      return this.$store.getters["ACCOUNT/uploadedAvatar"];
    }
  },
  methods: {
    async uploadAvatar() {
      await this.$store.dispatch("ACCOUNT/uploadAvatar", this.formData);
    },
    async updateInfo() {
      // Validate
      await this.$refs.form.validate();
      if (!this.userValid) return;
      // Check avatar change
      if (this.user.avatar.slice(0, 5) === "blob:") {
        await this.uploadAvatar();
      }
      // Request update
      await this.$store.dispatch("ACCOUNT/updateInfo", {
        name: this.user.name,
        avatar: this.uploadedAvatar
      });

      if (this.accountStatusRequest.value === "success-updateInfo") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã cập nhật hồ sơ"
          }
        });
        // Reset validate
        this.$refs.form.resetValidation();
      }
    }
  }
};
</script>
