<template>
  <v-row>
    <v-col class="px-5 py-0" cols="12">
      <account-profile></account-profile>
    </v-col>
    <v-col class="px-5 py-0 mt-5" cols="12">
      <account-password></account-password>
    </v-col>
  </v-row>
</template>

<script>
import AccountPassword from "./components/AccountPassword";
import AccountProfile from "./components/AccountProfile";
export default {
  components: {
    AccountPassword,
    AccountProfile
  }
};
</script>
