<template>
  <v-card flat>
    <v-card-title class="font-weight-bold px-5">Mật khẩu</v-card-title>
    <!-- Start: Alert Error -->
    <v-alert
      v-if="accountStatusRequest.value === 'error-updatePassword'"
      class="mb-8 mx-5"
      dense
      text
      type="error"
    >
      {{ accountStatusRequest.message }}
    </v-alert>
    <!-- End: Alert Error -->
    <!-- Start: Card Body -->
    <v-form v-model="passwordValid" ref="form" lazy-validation>
      <v-container class="px-5 py-0" fluid>
        <!-- Start: Current password -->
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <div class="font-weight-bold mb-2">Mật khẩu hiện tại</div>
            <v-text-field
              v-model="password.currentPassword"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập mật khẩu hiện tại"
              single-line
              required
              :rules="[passwordRules.required]"
              solo
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- End: Current password -->
        <!-- Start: New password -->
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <div class="font-weight-bold mb-2">Mật khẩu mới</div>
            <v-text-field
              v-model="password.newPassword"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Nhập mật khẩu mới"
              single-line
              required
              :rules="[passwordRules.required, passwordRules.newPasswordRules]"
              solo
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- End: New password -->
        <!-- Start: Confirm new password -->
        <v-row>
          <v-col class="py-0" cols="12" md="6">
            <div class="font-weight-bold mb-2">Xác nhận mật khẩu mới</div>
            <v-text-field
              v-model="password.confirmNewPassword"
              class="text-body-1"
              dense
              flat
              outlined
              placeholder="Xác nhận mật khẩu mới"
              single-line
              required
              :rules="[
                passwordRules.required,
                passwordRules.confirmNewPasswordRules
              ]"
              solo
              type="password"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- End: Confirm new password -->
        <!-- Start: Update button -->
        <v-row>
          <v-col class="pt-0 pb-5">
            <v-btn
              class="btn-text--normal"
              color="primary"
              depressed
              :disabled="!passwordValid"
              @click="passwordValid ? updatePassword() : null"
            >
              Cập nhật mật khẩu
            </v-btn>
          </v-col>
        </v-row>
        <!-- End: Update button -->
      </v-container>
    </v-form>
    <!-- End: Card Body -->
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      password: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null
      },
      passwordRules: {
        required: value => !!value || "Bạn chưa nhập dữ liệu.",
        newPasswordRules: value => {
          if (value && value.length > 5) return true;
          else return "Mật khẩu phải dài từ 6 ký tự trở lên.";
        },
        confirmNewPasswordRules: value => {
          if (value === this.password.newPassword) return true;
          else return "Mật khẩu mới không trùng khớp.";
        }
      },
      passwordValid: true
    };
  },
  computed: {
    accountStatusRequest() {
      return this.$store.getters["ACCOUNT/statusRequest"];
    }
  },
  methods: {
    async updatePassword() {
      // Validate
      await this.$refs.form.validate();
      if (!this.passwordValid) return;
      // Request update
      await this.$store.dispatch("ACCOUNT/updatePassword", {
        cur_pass: this.password.currentPassword,
        new_pass: this.password.newPassword,
        new_pass_con: this.password.confirmNewPassword
      });

      if (this.accountStatusRequest.value === "success-updatePassword") {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã cập nhật mật khẩu"
          }
        });
        // Reset password
        this.$refs.form.reset();
        // Reset validate
        this.$refs.form.resetValidation();
      }
    }
  }
};
</script>
